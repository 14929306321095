<template>
	<v-sheet class="recurring-main-listing" style="height: calc(87vh - 88px)">
		<v-layout class="" style="max-width: 300px !important">
			<v-flex class="py-0">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					solo
					v-on:change="statusFilterRows($event)"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
								<span>{{ item.status_count }}</span>
							</v-avatar>
						</v-list-item-action>
					</template>
				</v-select>
				<v-spacer></v-spacer>
			</v-flex>
		</v-layout>
		<template>
			<div class="overflow-y" style="height: calc(100vh - 306px)">
				<table width="100%" class="detail-table table-head-sticky">
					<thead>
						<tr>
							<th class="p-2 light-blue-bg custome-table">Action</th>
							<th class="p-2 light-blue-bg custome-table">Contract</th>
							<th class="p-2 light-blue-bg custome-table">Customer Details</th>
							<th class="p-2 light-blue-bg custome-table">Title</th>
							<th class="p-2 light-blue-bg custome-table">Service</th>
							<th class="p-2 light-blue-bg custome-table">Remark</th>
							<th class="p-2 light-blue-bg custome-table">Status</th>
							<th class="p-2 light-blue-bg custome-table">Create At</th>
						</tr>
					</thead>
					<tbody v-if="contractData.length">
						<tr v-for="(row, index) in contractData" :key="index">
							<td class="p-2 border-top-light-grey custome-table-td">
								<v-tooltip top content-class="custom-top-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											depressed
											:disabled="row.status != 'Draft' ? true : false"
											fab
											dark
											x-small
											color="green"
											v-bind="attrs"
											v-on="on"
											class="mr-3 custom-disabled"
											v-on:click.stop.prevent="updateContract(row.id)"
										>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</template>
									<span>Edit</span>
								</v-tooltip>
								<!--  <v-tooltip top content-class="custom-top-tooltip">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                depressed
                                                fab
                                                dark
                                                :disabled="row.status == 'Approved' || row.status == 'Rejected' ? true : false"
                                                x-small
                                                color="red"
                                                class="mr-3 custom-disabled-del"
                                                v-bind="attrs"
                                                v-on="on"
                                                v-on:click.stop.prevent="deleteConfirm(td)"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete</span>
                                    </v-tooltip> -->
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" v-on:click="routeToDetail(row.id)">
								<Chip class="text-capitalize mb-2" :text="row.barcode" color="cyan"></Chip>
								<div class="mb-2" v-if="row.convert_project == 1">
									<Chip
										text="Project Created"
										text-color=""
										outlined
										color="red"
										v-if="row.convert_project"
									></Chip>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" v-on:click="routeToDetail(row.id)">
								<div>
									<label class="font-size-14 mb-1 mr-1"
										><b>
											<v-icon size="18">mdi-pound</v-icon>
										</b></label
									>
									<span v-if="row.customer_barcode">{{ row.customer_barcode }} </span>
								</div>
								<div>
									<label class="font-size-14 mb-1 mr-1"
										><b>
											<v-icon size="18">mdi-account-circle</v-icon>
										</b></label
									>
									<span v-if="row.customer_company_name">{{ row.customer_company_name }}</span>
									<em v-else class="text-muted">no company name</em>
								</div>
								<div>
									<label class="font-size-14 mb-1 mr-1"
										><b>
											<v-icon size="18">mdi-account</v-icon>
										</b></label
									>
									<span v-if="row.user_name">
										<v-chip class="mb-1 mr-1" small>
											<v-avatar left small>
												<img v-if="row.profile_img" :src="row.profile_img" />
												<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
											</v-avatar>
											<span v-if="row && row.user_name">{{ row.user_name }}</span>
										</v-chip>
									</span>
									<em v-else class="text-muted">no sales person</em>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" v-on:click="routeToDetail(row.id)">
								<span v-if="row && row.title">
									{{ row.title }}
								</span>
								<em class="text-muted" v-else>no tile</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" v-on:click="routeToDetail(row.id)">
								<template v-if="row.checkbox_type">
									<span v-for="(row, index) in row.checkbox_type" :key="index" class="cursor-initial">
										<template v-if="row == 'seo'">
											<Chip text="SEO" color="blue" outlined text-color="" class="mr-1"> </Chip>
										</template>
										<template v-if="row == 'hosting'">
											<Chip text="H" color="blue-grey" outlined text-color="" class="mr-1"> </Chip>
										</template>
										<template v-if="row == 'ssl'">
											<Chip text="SSL" color="green" outlined text-color="" class="mr-1"> </Chip>
										</template>
										<template v-if="row == 'web'">
											<Chip text="W" color="orange" outlined text-color="" class="mr-1"> </Chip>
										</template>
										<template v-if="row == 'domain'">
											<Chip text="D" color="red" outlined text-color="" class="mr-1"> </Chip>
										</template>
										<template v-if="row == 'maintenance'">
											<Chip text="M" color="cyan" outlined text-color="" class="mr-1"> </Chip>
										</template>
									</span>
								</template>
								<em v-else class="text-muted">no service</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" v-on:click="routeToDetail(row.id)">
								<span v-if="row && row.remark">
									{{ row.remark }}
								</span>
								<em class="text-muted" v-else>no remark</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" v-on:click="routeToDetail(row.id)">
								<div class="mb-2">
									<Chip :text="row.status" color="green" v-if="row.status == 'Accepted'"></Chip>
								</div>
								<div class="mb-2">
									<Chip :text="row.status" color="red" v-if="row.status == 'Rejected'"></Chip>
								</div>
								<div class="mb-2">
									<Chip :text="row.status" color="cyan" v-if="row.status == 'Draft'"></Chip>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td" v-on:click="routeToDetail(row.id)">
								<span v-if="row && row.added_at">
									<Chip
										class="text-capitalize"
										:text="row.added_at"
										color="green"
										text-color=""
										outlined
									></Chip>
								</span>
							</td>
							<td
								class="p-2 border-top-light-grey custome-table-td"
								v-on:click="routeToDetail(row.id)"
								v-if="false"
							>
								<span v-if="contarct && contarct.seo_type">
									<Chip
										class="text-capitalize"
										:text="contarct.seo_type"
										color="green"
										text-color=""
										outlined
									></Chip>
								</span>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="7">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</template>
		<Contact
			v-on:success="(updateDialog = false), getContract()"
			v-on:refress="(updateDialog = false), getContract()"
			v-on:close="updateDialog = false"
			:update-dialog="updateDialog"
			:contract-id="contactId"
			:customer-id="customerId"
			internal
		>
		</Contact>
		<Dialog :dialog="urlsDialog" :dialog-width="1200">
			<template v-slot:title>Seo URL</template>
			<template v-slot:body>
				<ContractSoeUrl
					v-on:close="urlsDialog = false"
					:customer-url="customerService"
					:service-id="customerServiceId"
				>
				</ContractSoeUrl>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="urlsDialog = false"> Close </v-btn>
			</template>
		</Dialog>
		<v-row class="my-2">
			<v-col md="6" class="my-auto">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					v-on:input="updatePagination($event)"
				></v-pagination>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<style>
.custome-table {
	background-color: #f3f6f9 !important;
	border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/*  border-bottom: solid 1px #bcc0c9 !important; */
}
</style>
<script>
import ApiService from "@/core/services/api.service";
/* import ShowValue from "@/view/components/ShowValue"; */
import Chip from "@/view/components/Chip";
import ContractSoeUrl from "@/view/module/contract/Contract-Seo-Urls";
import Contact from "@/view/module/contract/Create-Contract";
import {
	SET_CURRENT_PAGE,
	SET_SHOWING_STRING,
	SET_TOTAL_PAGE,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { QUERY } from "@/core/services/store/request.module";
/* import { SET_TBODY } from "@/core/services/store/listing.module"; */
import { ContractEventBus } from "@/core/lib/contract.lib";

import Dialog from "@/view/components/Dialog";

export default {
	name: "Seo-report-listing",
	title: "Listing Invoice",
	/* mixins: [ListingMixin], */
	data() {
		return {
			pageTitle: "Seo-report",
			pageBreadcrumbs: [{ text: "Seo-report", disabled: true }],
			endpoint: "seo-report",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			customerServiceId: 0,
			urlsDialog: false,
			listingStatus: [],
			contractData: [],
			updateDialog: false,
			contactId: 0,
			page: 1,
			DataSearch: null,
			showing_string: null,
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			totalSelected: 0,
			paginationPageSize: 0,
		};
	},
	props: {
		customerId: {
			type: Number,
			default: 0,
		},
		customerService: {
			type: Array,
		},
		contarct: {
			type: Object,
		},
		projectId: {
			type: Number,
			default: 0,
		},
		contractId: {
			type: Number,
			default: 0,
		},
		seoId: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		customerId: {
			handler(param) {
				if (param) {
					this.getContract();
				}
			},
		},
	},
	methods: {
		/*  getContrectdata(id) {
			this.updateDialog = true;
			this.contactId = id;
		}, */
		detailUrls(id) {
			this.customerServiceId = id;
			this.urlsDialog = true;
		},
		updateContract(id) {
			this.updateDialog = true;
			this.contactId = id;
		},
		routeToDetail(id) {
			this.$router.push({
				name: "contract-detail",
				params: { id },
				query: { ...this.$route.query, t: new Date().getTime() },
			});
		},
		statusFilterRows(value) {
			//	this.allFiltersStatus(value);
			this.$store
				.dispatch(QUERY, {
					url: `contract`,
					data: {
						status: value,
						related_id: this.customerId,
					},
				})
				.then((data) => {
					this.contractData = data.tbody;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getContract(page) {
			/* let status = this.status; */
			/* 	if (status == "checked-out" && this.status_filter) {
				status = this.status_filter;
			} */
			ApiService.query(`contract`, {
				page: page,
				search: this.DataSearch,
				related_id: this.customerId,
			})
				.then(({ data }) => {
					this.contractData = data.tbody;
					this.showing_string = data.showing_string;
					this.total_page = data.total_page;
					this.listingStatus = data.status;
					this.$store.commit(SET_SHOWING_STRING, this.showing_string);
					this.$store.commit(SET_TOTAL_PAGE, this.total_page);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}

			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.getSeo(param);
			});
		},
	},
	components: {
		/*  ShowValue, */
		Chip,
		ContractSoeUrl,
		Dialog,
		Contact,
	},
	mounted() {
		this.getContract();
		ContractEventBus.$on("parent-refresh", () => {
			this.getContract();
		});
		/* console.log(this.showing_string,'sjdfshgf.fhsjhdajkshdjas')
		this.$store.commit(SET_SHOWING_STRING, this.showing_string); */
	},
	computed: {
		...mapGetters(["selected", "tbody", "currentPage", "totalPage", "showingString"]),
	},
};
</script>
